<template>
  <div class="recommentTabInfo">
    <Pullrefresh @onLoad="onLoad" :loading="loading" :finished="finished" @onRefresh="onRefresh" :refreshing="refreshing" :isNoData="isNoData" :firstLoading="firstLoading">
      <div v-if="mediaInfos.length > 0">
        <div class="recommentTabInfo-item" v-for="(item,index) in mediaInfos" :key="index">
          <div class="video" v-if="item.bType == 1" @click="jumpVideoInfo(item)">
            <div class="item-preview">
              <ImgDecypt :src="item.videoCover" />
              <div class="video-info flex-between">
                <div class="left">
                  <span class="left-play"></span>{{item.watchTimes|watchCount}}
                </div>
                <div class="right">{{item.playTime|videotime}}</div>
              </div>
            </div>
            <h2>{{item.content}}</h2>
            <div class="sell-content">
              <div class="sell-left">
                <div class="sell-box vip">
                  <div class="sell-vip">
                    VIP
                  </div>
                  <span>{{item.vipPrice}}金币</span>
                </div>
                <div class="sell-box novip">
                  <div class="sell-vip">
                    非VIP
                  </div>
                  <span>{{item.price}}金币</span>
                </div>
              </div>
              <div class="sell-right">
                <svg-icon :iconClass="item.isBuy || (item.fansGroup && item.fansGroup.isMember )?'icn_player' :'shopCard' " class="shopCard"></svg-icon>
              </div>
            </div>
            <CoveLabel class="cust_label" :item="item" isMax="true" />
          </div>
          <!-- 广告 -->
          <VideoAdv class="advs" v-if="item.bType == 4" :item="item" />
        </div>
      </div>
    </Pullrefresh>
  </div>
</template>
<script>
import { getRecommendList } from "@/api/on_demand.js";
import CoveLabel from "@/components/CoveLabel/index.vue";
import Pullrefresh from "@/views/widget/PullRefresh.vue";
import { jumpVideo } from "@/utils/index.js";
import VideoAdv from "@/views/widget/videoAdv.vue";
export default {
  components: {
    Pullrefresh,
    CoveLabel,
    VideoAdv,
  },
  props: {
    type: {
      require: true,
      type: String,
      default: "new",
    },
  },
  data() {
    return {
      loading: false, //下滑loading
      finished: false, //数据是否加载完毕
      refreshing: false, //下拉下载loading
      isNoData: false, //数据是否为空
      firstLoading: true, //是否第一次加载
      mediaInfos: [],
      getRecomendParams: {
        isVip: false,
        pageNum: 1,
        pageSize: 20,
        type: "new",
      },
    };
  },
  created() {
    this.getData();
  },
  methods: {
    //上滑加载
    onLoad() {
      this.getRecomendParams.pageNum++;
      this.getData();
    },
    //获取数据
    async getData() {
      this.loading = true;
      let req = this.getRecomendParams;
      req.type = this.type;
      let ret = await this.$Api(getRecommendList, this.getRecomendParams);

      this.loading = false;
      this.refreshing = false;
      this.firstLoading = false;
      if (ret && ret.code == 200 && ret.data && ret.data.mediaInfos != null) {
        let mediaInfos = ret.data.mediaInfos;
        this.mediaInfos = this.mediaInfos.concat(mediaInfos);

        if (mediaInfos.length == 0 && this.getRecomendParams.pageNum == 1) {
          this.isNoData = true;
          return;
        }

        if (ret.data.mediaInfos.length < this.getRecomendParams.pageSize) {
          this.finished = true;
        }
      }
    },
    //下拉刷新
    onRefresh() {
      this.getRecomendParams.pageNum = 1;
      this.refreshing = true;
      this.mediaInfos = [];
      this.finished = false;
      this.getData();
    },
    //视频详情
    jumpVideoInfo(item) {
      jumpVideo(item);
    },
  },
};
</script>
<style lang="scss" scoped>
.recommentTabInfo {
  margin: 5px 10px 0;
  .recommentTabInfo-item {
    position: relative;
    background: $whiteSeven;
    margin-bottom: 13px;
    border-radius: 6px;
    .item-preview {
      width: 100%;
      height: 174px;
      position: relative;
      //background: salmon;
      border-radius: 6px 6px 0 0;
      /deep/ img {
        border-radius: 6px 6px 0 0;
      }
    }
    h2 {
      font-size: 13px;
      padding: 10px 0 2px 10px;
      color: $blackFour;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    .sell-content {
      padding: 10px;
      display: flex;
      justify-content: space-between;

      .sell-left {
        display: flex;
        .sell-box {
          display: flex;
          margin-right: 10px;
          span {
            display: inline-block;
            padding: 0 5px;
          }

          .sell-vip {
            color: $white;
            width: 40px;
            height: 16px;
            line-height: 16px;
            text-align: center;
            font-size: 10px;
            background: linear-gradient(to right, $red, $vermillionFour);
            border-radius: 6px;
          }
        }
        .vip {
          span {
            color: $vermillionFour;
          }
        }
        .novip {
          .sell-vip {
            background: #707071;
          }
          span {
            color: #707071;
          }
        }
      }
      .sell-right {
        width: 40px;
        height: 23px;
        background: linear-gradient(to right, $red, $vermillionFour);
        border-radius: 6px;
        display: flex;
        align-items: center;
        justify-content: center;
        .shopCard {
          width: 25px;
          height: 15px;
        }
      }
    }
    .advs {
      height: 73.95px;
      border-radius: 4px;
      overflow: hidden;
    }
  }
}
//图标
.cust_label {
  position: absolute;
  top: 0;
  right: 0;
}

//播放次数 时间
.video-info {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 30px;
  align-items: center;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.2));
  padding: 10px 15px;
  box-sizing: border-box;
  font-size: 12px;
  color: #ffffff;
  .left {
    display: flex;
    align-items: center;
    .left-play {
      display: inline-block;
      margin-right: 5px;
      width: 10px;
      height: 10px;
      background: url("../../../assets/png/play_icon.png") center center
        no-repeat;
      background-size: 100%;
    }
  }
}
</style>
