<template>
  <div class="videoAdv" @click="jump(item.adv)">
    <ImgDecypt :src="item.adv&&item.adv.pictureUrl" />
  </div>
</template>
<script>
import { jumpAdv } from "@/utils/getAdv.js";
export default {
  props: {
    item: {
      default: {},
    },
  },
  methods: {
    jump(item) {
      jumpAdv(item);
    },
  },
};
</script>

<style lang="scss" scoped>
.videoAdv {
  width: 100%;
  height: 150px;
}
</style>