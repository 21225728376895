import axios from "@/utils/request";

//获取点播推荐
export function getRecommendList(data) {
  return axios.get(`/media/most`, {
    params: data
  });
}

// 获取所有的粉丝团
export function findFansGroup(data) {
  return axios.post(`/fansgroup/list`, data);
}

//获取点播媒体
export function getMediaList(data) {
  return axios.post(`/media/list`, data);
}

//获取av解说数据
export function getAvdata(data) {
  return axios.post(`/avcomment/list`, data);
}

//获取点播原创
export function bloggerRanking(data) {
  return axios.get(`/blogger/ranking`, {
    params: data
  });
}

//点播更多视频
export function getUseDetail(data) {
  return axios.post(`/media/user`, data);
}

// 关注
export function sendUserCare(data) {
  return axios.post(`/care/user`, data);
}

//获取博主详情
export function getBlogInfo(data) {
  return axios.post(`/blogger/detail`, data);
}
//获取博主详情
export function getAvDetail(data) {
  return axios.post(`/avcomment/one`, data);
}

//av解说收藏
export function collectAvCommentary(data) {
  return axios.post(`/collect/collectUp`, data);
}
//av解说取消收藏
export function cancelCollectAvCommentary(data) {
  return axios.post(`/collect/collectDown`, data);
}

//av解说取消收藏
export function likeAvCommentary(data) {
  return axios.post(`/like/thumbsUp`, data);
}

//av解说取消收藏
export function cancelLikeAvCommentary(data) {
  return axios.post(`/like/thumbsDown`, data);
}

/// 加入粉丝团
export function joinFansGroup(data) {
  return axios.post(`/fansgroup/join`, data);
}